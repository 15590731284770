<template>
  <content-list-api :template="template" @view="view">
    <template v-slot:title-controller><slot name="title-controller"/></template>
    <template v-slot:card-controller="{ item }">
      <v-list-item-icon class="pt-3 pb-1">
        <gender-type :gender="item.genderType" />
      </v-list-item-icon>
      <v-list-item-icon class="pt-2 pb-2">
        <v-btn
          @click="
            $event.stopPropagation();
            call(item);
          "
          small
          color="#eeeeee"
          class="service_button"
          fab
          elevation="0"
        >
          <v-icon>mdi-phone </v-icon>
        </v-btn>
      </v-list-item-icon>
    </template>
  </content-list-api>
</template>

<script>
import ContentListApi from "@/components/cards/ContentListApi";
import GenderType from "@/components/GenderType.vue";
import moment from "moment";

export default {
  props: {
    alias: {
      type: String,
      default: "patients",
    },
  },
  computed: {
    clinicID() {
      return this.$store.getters["Authen/getRole"].clinicID;
    },
  },
  components: { ContentListApi, GenderType },
  data: () => ({
    template: null,
  }),
  mounted() {
    this.render({ keySearch: "" });
  },
  watch: {
    clinicID() {
      this.render({ keySearch: "" });
    },
  },
  methods: {
    async render({ keySearch }) {
      var clinicID = this.clinicID;
      if (!clinicID) {
        return (this.template = {
          title: this.alias,
          noDataMessage:
            "You have not created any patients. Start to create one!",
        });
      }
      this.template = {
        title: this.alias,
        api: {
          url: `/owner/clinics/${clinicID}/${this.alias}`,
          query: {
            keySearch,
          },
          headers: {
            page: 1,
            limit: this.alias == "patients" ? 10 : 3,
          },
        },
        transform: {
          id: "userID",
          title: "fullName",
          image: {
            field: "avatar",
            transform(val) {
              return val || "icons/user.png";
            },
          },
          content: {
            field: "dob",
            transform(val) {
              return `Dob: ${moment(val).format("DD-MM-YYYY")}`;
            },
          },
        },
      };
    },
    call(item) {
      var { phone } = item;
      if (phone) window.open(`tel:${phone}`);
    },
    view(item) {
      this.$emit("view", item);
    },
  },
};
</script>
