<template>
  <div class="info__area">
    <div class="info_layout">
      <div class="title_area">
        <div class="user__detail mb-10">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
